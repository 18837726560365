














import { computed, defineComponent, reactive, ref, set } from '@vue/composition-api'
import { getProjectApi } from '@/api/project'
import { showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import ProjectGroupList from './components/projectGroup/ProjectGroupList.vue'
import ProjectGroupEdit from './components/projectGroup/ProjectGroupEdit.vue'
import { useProjectStore } from '@/pinia/modules/project'
export default defineComponent({
    components: {
        ProjectHeader,
        ProjectGroupList,
        ProjectGroupEdit
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const detailObj: any = reactive({})
        const projectStore = useProjectStore()
        const getProject = async () => {
            try {
                const { details } = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        projectStore.updateProjectSegments(projectUuId.value)

        const projectGroupListRef = ref()
        const projectGroupEditRef = ref()
        const segmentObj = reactive({
            edit: (item: any) => {
                projectGroupEditRef.value.segmentObj.edit(item)
            },
            add: () => {
                projectGroupEditRef.value.segmentObj.add()
            },
            cancel: () => {},
            update: () => {
                console.log(`update getSegments`)
            }
        })
        return {
            projectUuId,
            detailObj,
            segmentObj,
            projectGroupListRef,
            projectGroupEditRef
        }
    }
})
